/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'

import { Projects } from '../components/Showcase'

import Gettouch from '../components/Gettouch'
import Headinfo from '../components/Headinfo'
import '../assets/styled-min.css'
import { Helmet } from 'react-helmet'

class work extends React.Component {
  
  render() {
    const projects = this.props.data.allContentfulStudioCasePage
    return (
      <Layout>
        <Helmet>
          <title>Urbian | Work</title>
        </Helmet>
        <Projects projects={projects}/>
        {/* <CallToAction {...data.showcaseYaml.cta} /> */}
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    )
  }
}

export default work



export const contentfulWorkPageQuery = graphql`
  query workMainPageWpQuery {
    allContentfulStudioCasePage {
      edges {
        node {
          workpageserviceslist {
            workpageserviceslist
          }
          workCardThumbnailImage {
            fluid(maxWidth: 800){
              ...GatsbyContentfulFluid_withWebp
            }
            id
          }
          internalPageName
          clientColour
          manyReferencesSection {
            ... on ContentfulHeroSectionCases {
              id
              title
            }
          }
        }
      }
    }
  }
`
