/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import './styled.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

const Projects = () => {
  const ProjectsImages = useStaticQuery(graphql`
    query ProjectsImages {
        foonda: file(absolutePath: {regex: "/assets/images/FoondaThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        detsa: file(absolutePath: {regex: "/assets/images/DETSAThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        fido: file(absolutePath: {regex: "/assets/images/FidoThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        grow: file(absolutePath: {regex: "/assets/images/GrowNationThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        standardbank: file(absolutePath: {regex: "/assets/images/StandardBankThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        highfive: file(absolutePath: {regex: "/assets/images/Hi5Thumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        investec: file(absolutePath: {regex: "/assets/images/InvestecThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        libertydirect: file(absolutePath: {regex: "/assets/images/DLISThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        spw: file(absolutePath: {regex: "/assets/images/SPWThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        babylonstoren: file(absolutePath: {regex: "/assets/images/BabylonstorenThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
    <div className="content-wrap">
      <Container>
        <Row className="sp-5">
          <Col md="8" lg="7" xl="6" className="introduction">
            {' '}
            <h1>Our work.</h1>
            <p>
              For over a decade we have helped ambitious companies re-imagine
              customer experiences and create new models for growth and build
              new capabilities.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="sp-8">
        <Row id="workgrid">
        <Col sm="6">
          <a target="_blank" href="https://lifeinsurance.standardbank.co.za/life-cover">
            <div className="project-img">
              <Image alt="Standard Bank"
                  sizes={ProjectsImages.standardbank.childImageSharp.sizes}
              />
            </div>
            <small className="clientname stndbk">Standard Bank</small>
            <h6 className="h3">
              A digital transformation led fully underwritten online insurance experience launched in record time.
            </h6>
            <p className="technologies">
              Product Management, UI/UX Design, Front-End Development, Back-End Development, Capacity Building
            </p>
          </a>
        </Col>
          <Col sm="6">
            <Link to="/STSC/">
              <div className="project-img">
                <Image alt="DETSA"
                    sizes={ProjectsImages.detsa.childImageSharp.sizes}
                />
              </div>
              <small className="clientname diageo">
                Diageo empowerment trust SA
              </small>
              <h6 className="h3">
                Re-vitalising an African-centric social tech incubator program.
              </h6>
              <p className="technologies">
                Branding, Strategy, Program Management, Content Creation,UI/UX Design, Software Development
              </p>
            </Link>
          </Col>
          <Col sm="6">
            <a target="_blank" href="https://www.get5.io/">
              <div className="project-img">
                <Image alt="Hi5"
                    sizes={ProjectsImages.highfive.childImageSharp.sizes}
                />
              </div>
              <small className="clientname hifive">
                Hi5
              </small>
              <h6 className="h3">
                Making HR software great again.
              </h6>
              <p className="technologies">
                Branding, Strategy, Funding, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </a>
          </Col>
          <Col sm="6">
            <a target="_blank" href="http://grownationfund.com/">
              <div className="project-img">
                <Image alt="GrowNation"
                    sizes={ProjectsImages.grow.childImageSharp.sizes}
                />
              </div>
              <small className="clientname cheersz">CHEERS Z VENTURES</small>
              <h6 className="h3">
                From an idea to over 2,000 rural farmers registered in 4 months.
              </h6>
              <p className="technologies">
                Branding, Strategy, Product Management, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </a>
          </Col>
          <Col sm="6">
            <a target="_blank" href="https://www.investec.com/en_za/One-Place.html">
              <div className="project-img">
                <Image alt="Investec"
                  sizes={ProjectsImages.investec.childImageSharp.sizes}
                />
              </div>
              <small className="clientname investec">
                Investec
              </small>
              <h6 className="h3">
                Helping a financial services giant extend their reach to a broader market.
              </h6>
              <p className="technologies">
                Product Strategy, Product Management, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </a>
          </Col>
          <Col sm="6">
            <div className="comingsoon">
              <div className="project-img">
                <Image alt="Liberty Direct"
                    sizes={ProjectsImages.libertydirect.childImageSharp.sizes}
                />
              </div>
              <small className="clientname libertydirect">Liberty Direct</small>
              <h6 className="h3">
                Clearing a 9-year old feature backlog in 6 months.
              </h6>
              <p className="technologies">
                Product Strategy, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </div>
          </Col>
          <Col sm="6">
            <a target="_blank" href="https://sanlamprivatewealth.sanlam.com/">
              <div className="project-img">
                <Image alt="Sanlam Private Wealth"
                    sizes={ProjectsImages.spw.childImageSharp.sizes}
                />
              </div>
              <small class="clientname spw">Sanlam Private Wealth</small>
              <h6 className="h3">
                A design system led new brand home generates R150 million of new business leads in 4 months.
              </h6>
              <p className="technologies">
                Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </a>
          </Col>
          <Col sm="6">
            <div className="comingsoon">
              <div className="project-img">
                <Image alt="Frank Financial Services"
                    sizes={ProjectsImages.fido.childImageSharp.sizes}
                />
              </div>
              <small className="clientname ffs">
                Frank Financial Services
              </small>
              <h6 className="h3">
                Fun, quick and easy online credit reports get over 160,000 checks requested in the first 6 months.
              </h6>
              <p className="technologies">
                Branding, Strategy, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </div>
          </Col>
          <Col sm="6">
            <div className="comingsoon">
              <div className="project-img">
                <Image alt="Foonda"
                    sizes={ProjectsImages.foonda.childImageSharp.sizes}
                />
              </div>
              <small className="clientname foonda">Foonda</small>
              <h6 className="h3">
                A new education funding platform start-up launched to market in 3 months.
              </h6>
              <p className="technologies">
                Branding, Product Management, UI/UX Design, Front-End Development, Back-End Development
              </p>
            </div>
          </Col>
          <Col sm="6">
            <div className="comingsoon">
              <div className="project-img">
                <Image alt="Babylonstoren"
                    sizes={ProjectsImages.babylonstoren.childImageSharp.sizes}
                />
              </div>
              <small className="clientname babylonstoren">
                Babylonstoren
              </small>
              <h6 className="h3">
                An end-to-end custom e-commerce implementation increased sales by 150% in under 6 months.
              </h6>
              <p className="technologies">
                Strategy, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development, Capacity Building
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Projects
